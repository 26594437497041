import type { NextPage } from 'next'

import styles from '../styles/Home.module.css'
import Head from 'next/head';

const Login: NextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>openigloo</title>
        <meta name="description" content="openigloo" />
        {/* <link rel="icon" href="/favicon.ico" /> */}
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Welcome to <a href="https://www.openigloo.com">openigloo!</a>
        </h1>
      </main>

    </div>
  )
}

export default Login
